import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-089a3ee0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBox = _resolveComponent("SearchBox")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_main = _resolveComponent("el-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SearchBox, {
          placeholder: "Order Id",
          clickSearch: _ctx.searchKeyword
        }, null, 8, ["clickSearch"])
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _ctx.data?.data,
        border: "",
        fit: "",
        "highlight-current-row": "",
        style: {"width":"100%"},
        onSortChange: _ctx.sortChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "ID"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.id), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "ORDER ID",
            width: "120"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.orderId), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "USER"
          }, {
            default: _withCtx(({row}) => [
              _createVNode(_component_router_link, {
                to: `/users/${row.id}`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(row.username), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "PLAN"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.plan), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "AMOUNT",
            width: "120"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.amount), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "PAYMENT METHOD",
            width: "160"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.paymentMethod), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "TRANSACTION NUMBER",
            width: "190"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.transactionId), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "PAID AT",
            width: "180"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.paidAt), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "TRANSACTION FEE",
            width: "190"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.transactionFee), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "SETTLEMENT FEE",
            width: "190"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.settlementFee), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "RESERVE FEE",
            width: "120"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.reserveFee), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "TOTAL AMOUNT",
            width: "150"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.totalAmount), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "CREATED AT",
            width: "180"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.createdAt), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: "UPDATED AT",
            prop: "updated_at",
            width: "180",
            sortable: "custom"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.updatedAt), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "onSortChange"])), [
        [_directive_loading, _ctx.isLoading || _ctx.isFetching]
      ]),
      _createVNode(_component_el_pagination, {
        currentPage: _ctx.page,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
        "page-size": _ctx.data?.meta?.perPage,
        layout: "prev, pager, next, jumper",
        total: _ctx.data?.meta?.total
      }, null, 8, ["currentPage", "page-size", "total"])
    ]),
    _: 1
  }))
}