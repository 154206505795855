
import { defineComponent, ref } from 'vue';

import { useOrders } from '@/composables/api';
import SearchBox from '@/components/keyword-searching/Index.vue';

export default defineComponent({
  components: {
    SearchBox
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const sortBy = ref();
    const descSortBy = ref('updated_at');

    const { data, isLoading, isFetching } = useOrders({ page, keyword, sortBy, descSortBy });

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      searchKeyword,
      sortChange
    };
  }
});
